<template>
  <div class="mt-5">
    <p class="lead">Localização</p>
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3074.384804717156!2d-46.5884491982977!3d-23.6014534963155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce5c7bcc8c5457%3A0x306e3e5deac543a6!2sR.%20Auriverde%2C%201720%20-%20Vila%20Independencia%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2004222-002!5e0!3m2!1sen!2sbr!4v1621944503470!5m2!1sen!2sbr" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Localização'
  }
};
</script>

<style>
</style>